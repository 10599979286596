import React from 'react';
import { DependencyGraphTypes } from '@backstage/core-components';
import { EntityNode } from '@backstage/plugin-catalog-graph';
import {
    Group,
    SupervisedUserCircle,
    Person,
    Language,
    Settings,
    Build,
    Extension,
    DeviceHub,
    Public,
    VpnLock,
    BusinessCenter,
    Face,
    Help,
    Apartment,
    Storage,
    LinearScale,
} from '@material-ui/icons'
import EntityIcon from './EntityIcon';
import { ApiStreamIcon, DataLakeTableIcon, DatabaseIcon, StreamIcon } from './ResourceIcon';
import { Api, GroupType, InterfaceExposureType, ServiceType } from './types';


export type NodeProps = {
    node: DependencyGraphTypes.DependencyNode<EntityNode>;
}

export type CoordProps = {
    width: number;
    height: number;
}

export type ClassNameProps = {
    className?: string;
}

const ICON_SIZE = 24;


export const renderNode: DependencyGraphTypes.RenderNodeFunction<EntityNode> =
    (props: DependencyGraphTypes.RenderNodeProps<EntityNode>) => {
        return <KindAndTypeSelectionIcon node={props.node} />
    }


export class KindAndTypeSelectionIcon extends React.Component<NodeProps> {
    render() {
        const node = this.props.node;
        const kind = node.kind || '<unknown kind>';
        const type = node.spec?.type || '<unknown type>';

        // Workaround for "infinite re-painting and zooming out":
        // On runtime, the node object has a width property (but no height). However, it is not in the type definition.
        // Setting the height causes the UI to re-paint in a loop and zoom out indefinitely.
        const width = (node as any).width || (node.name.length * 10 + 50);
        // The available height is determined by specifying the height here:
        const height = 82; // 82 has proven to be sensible for UI pov.
        // available space and coordinates:
        // 0 0 width height
        // If you draw something, try to center it in that available space.

        let icon;
        switch (kind) {
            case 'Component':
                switch (type) {
                    case 'service':
                        const serviceType: ServiceType | string = this.props.node.spec?.serviceType as string;
                        const serviceTypeComp = serviceType && <g>
                            <text x={width - 5} y={5} textAnchor='end' alignmentBaseline='hanging'>{serviceType}</text>
                        </g>
                        icon = <Settings width={ICON_SIZE} height={ICON_SIZE} />
                        return <EntityIcon
                            className={'componentIcon-service'}
                            width={width}
                            height={height}
                            node={this.props.node}
                            icon={icon}
                            topRightComponent={serviceTypeComp}
                        />;
                    case 'library':
                        icon = <Build width={ICON_SIZE} height={ICON_SIZE} />
                        return <EntityIcon
                            className={'componentIcon'}
                            width={width}
                            height={height}
                            node={this.props.node}
                            icon={icon}
                        />;
                    case 'website':
                        icon = <Language width={ICON_SIZE} height={ICON_SIZE} />
                        return <EntityIcon
                            className={'componentIcon'}
                            width={width}
                            height={height}
                            node={this.props.node}
                            icon={icon}
                        />;
                    case 'data-pipeline':
                        icon = <LinearScale width={ICON_SIZE} height={ICON_SIZE} />
                        return <EntityIcon
                            className={'componentIcon'}
                            width={width}
                            height={height}
                            node={this.props.node}
                            icon={icon}
                        />;
                    default:
                        // The other values (default case) do not have a custom UI so far: 
                        // application, misc
                        return <EntityIcon
                            className={'componentIcon'}
                            showSubtitle={true}
                            width={width}
                            height={height}
                            node={this.props.node}
                        />;
                }
            case 'Resource':
                switch (type) {
                    case 'database':
                        return <DatabaseIcon width={width} height={height} node={node} />;
                    case 'stream':
                        return <StreamIcon width={width} height={height} node={node} />;
                    case 'datalake-table':
                        return <DataLakeTableIcon width={width} height={height} node={node} />;
                    default:
                        // The other values (default case) do not have a custom UI so far.
                        const icon = <Storage width={ICON_SIZE} height={ICON_SIZE} />;
                        return <EntityIcon showSubtitle={true} width={width} height={height} node={this.props.node} icon={icon} />;
                }
            case 'API':
                return <ApiIcon width={width} height={height} node={node} />;
            case 'System':
                return <SystemIcon width={width} height={height} node={node} />;
            case 'User':
                return <UserIcon width={width} height={height} node={node} />;
            case 'Group':
                return <GroupIcon width={width} height={height} node={node} />;
            default:
                // The other values (default case) do not have a custom UI so far: 
                icon = <Help width={ICON_SIZE} height={ICON_SIZE} />;
                return <EntityIcon showSubtitle={true} width={width} height={height} node={this.props.node} icon={icon} />;
        }
    }
}

class ApiIcon extends React.Component<NodeProps & CoordProps> {
    render() {
        const width = this.props.width;
        const height = this.props.height;


        const entity = (this.props.node as unknown) as Api;

        const apiType = entity.spec?.type

        // Exposure type:
        const exposureType: InterfaceExposureType = entity.spec.interfaceExposureType || 'system-internal';
        const leftPad = apiType === 'asyncapi' ? 15 : 5

        const exposureTypeComp =
            <g className='topLeft'>
                {exposureType === 'system-interface' ?
                    <Public x={leftPad} width={ICON_SIZE} height={ICON_SIZE} />
                    :
                    <VpnLock style={{ fill: 'black' }} x={leftPad} width={ICON_SIZE} height={ICON_SIZE} />
                }
            </g>

        // API type:
        const apiTypeComp = apiType &&
            <g className='topRight'>
                <text x={width - (apiType === 'asyncapi' ? 35 : 5)} y={5} textAnchor='end' alignmentBaseline='hanging'>{apiType}</text>
            </g>

        if (apiType === 'asyncapi') {
            const className = `apiIcon-stream ${exposureType}`;
            return <ApiStreamIcon
                className={className}
                width={width}
                height={height}
                node={this.props.node}
                topLeftComponent={exposureTypeComp}
            />;

        }
        const className = `apiIcon ${exposureType}`;

        const icon = <Extension width={ICON_SIZE} height={ICON_SIZE} />;

        return <EntityIcon
            className={className}
            width={width}
            height={height}
            node={this.props.node}
            icon={icon}
            topRightComponent={apiTypeComp}
            topLeftComponent={exposureTypeComp}
        />;
    }
}

class SystemIcon extends React.Component<NodeProps & CoordProps> {
    render() {
        const icon = <DeviceHub width={ICON_SIZE} height={ICON_SIZE} />;
        return <EntityIcon
            className='systemIcon'
            width={this.props.width}
            height={this.props.height}
            node={this.props.node}
            icon={icon}
        />;
    }
}

class UserIcon extends React.Component<NodeProps & CoordProps> {
    render() {
        const icon = <Person width={ICON_SIZE} height={ICON_SIZE} />
        return <EntityIcon
            className='userIcon'
            width={this.props.width}
            height={this.props.height}
            node={this.props.node}
            icon={icon}
        />;
    }
}

class GroupIcon extends React.Component<NodeProps & CoordProps> {
    render() {
        const type = this.props.node.spec?.type as GroupType | string
        let icon;
        let className = 'groupIcon';
        switch (type) {
            case 'vertical':
                icon = <Apartment width={ICON_SIZE} height={ICON_SIZE} />
                break;
            case 'business-unit':
                icon = <BusinessCenter width={ICON_SIZE} height={ICON_SIZE} />
                break;
            case 'group':
                className += ' groupIcon-group'
                icon = <SupervisedUserCircle width={ICON_SIZE} height={ICON_SIZE} />
                break;
            case 'team':
                className += ' groupIcon-team'
                icon = <Group width={ICON_SIZE} height={ICON_SIZE} />
                break;
            default:
                icon = <Face width={ICON_SIZE} height={ICON_SIZE} />
        }
        return <EntityIcon
            className={className}
            width={this.props.width}
            height={this.props.height}
            node={this.props.node}
            icon={icon}
        />;

    }
}

