import React from 'react';
import { RepoScanResultData, RepoTool } from './ScannerApi';
import {
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';

interface Props {
  repo: RepoScanResultData | undefined;
  tools: RepoTool[] | undefined;
}

const useStyles = makeStyles(theme => ({
  chips: {
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export const RepositoryData = ({ repo, tools }: Props) => {
  const classes = useStyles();
  if (!repo) {
    return <div>Repository scan results were not found</div>;
  }

  const fileKeys = Object.keys(repo.count_files);
  const gitFileKeys = Object.keys(repo.git_languages);
  const notDetected = 'Not detected';

  const toolsWithDefault = tools || [];

  return (
    <Grid item container xs={12}>
      <Grid item xs={8}>
        <TableContainer component={Paper}>
          <Table aria-label="info table" size="small">
            <TableHead style={{ whiteSpace: 'nowrap' }}>
              <TableRow>
                <TableCell>Info</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody style={{ whiteSpace: 'nowrap' }}>
              <TableRow>
                <TableCell>Languages / Tools:</TableCell>
                <TableCell>
                  <div className={classes.chips}>
                    {toolsWithDefault.map((tool, idx) => {
                      const label = `${tool.tool_name} ${
                        tool.revisions && tool.revisions.length > 0
                          ? `: ${tool.revisions.join(', ')}`
                          : ''
                      }`;
                      return (
                        <div key={idx}>
                          <Chip label={label} />
                        </div>
                      );
                    })}
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Template features:</TableCell>
                <TableCell>
                  {repo.template_features && repo.template_features.length > 0
                    ? repo.template_features.join(',')
                    : notDetected}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Files found:</TableCell>
                <TableCell>
                  {fileKeys.map(key => (
                    <div key={key}>
                      {key}: {repo.count_files[key]}
                    </div>
                  ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>IaC kinds:</TableCell>
                <TableCell>
                  {repo.iac_kinds.length > 0
                    ? repo.iac_kinds.join(',')
                    : notDetected}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  {/* git table */}
                  <Table aria-label="info table" size="small">
                    <TableHead style={{ whiteSpace: 'nowrap' }}>
                      <TableRow>
                        <TableCell>Github Stats</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ whiteSpace: 'break-spaces' }}>
                      <TableRow>
                        <TableCell>PRs count:</TableCell>
                        <TableCell>{repo.git_pr_count}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Issues count</TableCell>
                        <TableCell>
                          <b>{repo.git_issues_count}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell> Size</TableCell>
                        <TableCell>
                          <b>{repo.git_size}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Is fork</TableCell>
                        <TableCell>
                          <b>
                            {repo.git_fork ? 'yes' : 'no'} (forks:{' '}
                            {repo.git_forks_count})
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Git languages</TableCell>
                        <TableCell>
                          {gitFileKeys.map(key => (
                            <div key={key}>
                              {key}: {repo.git_languages[key]}
                            </div>
                          ))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell> Commits (wekly)</TableCell>
                        <TableCell>
                          {' '}
                          <b>{repo.git_commits_weekly.join(', ')}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell> Commits last year</TableCell>
                        <TableCell>
                          <b>{repo.git_commits_year_total}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Created At</TableCell>
                        <TableCell>
                          <b>{repo.git_created_at}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Updated At</TableCell>
                        <TableCell>
                          <b>{repo.git_updated_at}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Pushed AT</TableCell>
                        <TableCell>
                          {' '}
                          <b>{repo.git_pushed_at}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Contributors</TableCell>
                        <TableCell>
                          {repo.git_contributors.join(', ')}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {/* git table */}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* <pre>{JSON.stringify(repo, undefined, 4)}</pre> */}
      </Grid>
    </Grid>
  );
};
