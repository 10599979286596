import { generatePath } from 'react-router';
import { DependencyGraphTypes } from '@backstage/core-components';
import { EntityNode } from '@backstage/plugin-catalog-graph';
import { ApiEntity, ComponentEntity, GroupEntity, LocationEntity, ResourceEntity, SystemEntity, UserEntity } from '@backstage/catalog-model';

/**
 * Native backstage entity kinds (`kind`).
 * See also https://backstage.io/docs/features/software-catalog/descriptor-format#spectype-required
 */
export type Kind =
    ApiEntity['kind'] |
    ComponentEntity['kind'] |
    ResourceEntity['kind'] |
    SystemEntity['kind'] |
    UserEntity['kind'] |
    GroupEntity['kind'] |
    LocationEntity['kind']


/**
 * The type of the API.
 * 
 * - 'openapi' for REST APIs (default in AS24).
 * - 'graphql' for GraphQL APIs.
 * - 'asyncapi' for Async APIs (like websocket) and also for "streams" like Kafka topics, SNS, SQS, EventBridge or Kinesis.
 * - 'wsdl' for any (non-REST) CashStack APIs (SOAP, WS-*, ...).
 * - 'grpc' for gRPC APIs (not used in AS24).
 */
export type ApiType = 'openapi' | 'graphql' | 'asyncapi' | 'wsdl' | 'grpc'

/**
 * Tells if an API is considered an interface to a system (re-use allowed), or just an system internal API that must not be re-used
 * 
 * Depending on this this type, we will demand different levels of documentation and quality.
 * 
 * - 'interface' for system interfaces (default in AS24). Re-use is allowed/encouraged.
 * - 'system-internal' for system-internal APIs. Re-use is not allowed.
 */
export type InterfaceExposureType = 'system-interface' | 'system-internal'

/** An interface. See https://backstage.io/docs/features/software-catalog/descriptor-format#kind-api */
export type Api = ApiEntity & {
    spec: {
        type: ApiType;
        /** Defaults to `system-internal` */
        interfaceExposureType?: InterfaceExposureType;
        /** Only applies if the `spec/type` is `asyncapi`. */
        streamType?: StreamType;
    }
}


/**
 * The type of the component (as in compilation unit/runnable code).
 * 
 * - 'service' for anything that runs code or other business logic (like EC2, Infinity, Lambda). 
 *    Note: If the service exposes a website, then you'd have to **additionally** model it as a `website`.
 * - 'library' for any custom library.
 * - 'website' for any domain and path that exposes a website (e.g. as24.com/home). 
 *    Note that this website might be powered by a service, or a static site from CDN, or by other means (which must be modelled **additionally**).
 *    A website must document all its dependencies. 
 *    This includes consumed APIs, and the components that power it (e.g. Infinity service, or S3 bucket for static content).
 * - 'data-pipeline' for Data Runner pipelines (or similar). Note: a Kafka enricher or Lambda must be a service, not a pipeline. Do not model CI/CD pipelines with this.
 */
export type ComponentType = 'service' | 'library' | 'website' | 'data-pipeline'

/**
 * The type of the service.
 * Only applies if the `spec/type` is `service`.
 * 
 * - 'Infinity' for Infinity services (default in AS24).
 * - 'EC2' for AMI-based EC2 services.
 * - 'Lambda' for any Lambda functions (or state machines).
 * - 'Fargate' for any Fargate services.
 */
export type ServiceType = 'Infinity' | 'EC2' | 'Lambda' | 'Fargate' | 'ECS'

/**
 * The type of the website.
 * 
 * - If it offers a standalone website, then it must add `standalone-website` to the `spec.websiteTypes` array.
 * - A website is usually delivered by a service or S3 bucket resource. Then, it must use a `dependsOn` relation to the service (or buckets).
 * - A website usually consumes (`consumesApis`) some APIs from the very same service (expect SEO pages, for examples), and potentially from other APIs too.
 * - Jigsaw:
 *   - If it offers a jigsaw fragment, then the `spec.websiteTypes` array must contain `jigsaw-fragment`.
 *   - If it offers a jigsaw page, then the `spec.websiteTypes` array must contain `jigsaw-page`.
 *   - If it uses other jigsaw fragments (e.g. a main page composed of jigsaw fragments) it must `dependsOn` on all included fragments (`component:<DEPENDENCY-website>`). 
 *   - If it is a jigsaw page or includes other fragments, it must additionally add a `dependsOn` towards the `component:jigsaw` [service](https://portal.services.as24.tech/catalog/default/component/jigsaw).
 */
export type WebsiteType = 'standalone-website' | 'jigsaw-fragment' | 'jigsaw-page'

/** A "component". See https://backstage.io/docs/features/software-catalog/descriptor-format#kind-component */
export type Component = ComponentEntity & {
    spec: {
        type: ComponentType;
        serviceType: ServiceType;
        /** Only applies if 'type:website'. Defaults to `system-internal` */
        interfaceExposureType?: InterfaceExposureType;
        /** Only applies if 'type:website'.  */
        websiteTypes?: WebsiteType[];
    }
}


/**
 * The type of the resources.
 * 
 * - 'database' for any databases with persistent storage. Ignore EBS volumes.
 * - 'stream' for any asynchronous communication (like Kafka topics, SNS, SQS, EventBridge or Kinesis).
 * - 'datalake-table' for tables in the datalake. These should be the most minimal tables avaialble, and not aggregated view on them.
 * - 'tooling': Everything around tooling like fizz, fast, github, portal.
 * - 'aws-account': Standalone code only for creating independent AWS resources.
 * - 'script': Standalone scripts which are only for used for local development.
 */
export type ResourceType = 'database' | 'cache' | 'stream' | 'datalake-table' | 'tooling' | 'aws-account' | 'script'

/**
 * The type of the stream. Only applies if the `spec/type` is `stream`.
 * 
 * The term "stream" is used in a broad sense here, including any kind of asynchronous communication.
 * 
 * - 'Kafka' for Kafka topics.
 * - 'Firehose' for Firehose streams.
 * - 'SNS' for SNS topics.
 * - 'SQS' for SQS queues.
 * - 'EventBridge' for EventBridge topics.
 */
export type StreamType = 'Kafka' | 'Firehose' | 'SNS' | 'SQS' | 'EventBridge'

/** They type of database. Only applies if the `spec/type` is `database`.
 * 
 * - 'DynamoDB' for DynamoDB tables.
 * - 'S3' for S3 buckets.
 * - 'RDS' for RDS databases.
 * - 'ElasticSearch' for ElasticSearch clusters.
 */
export type DatabaseType = 'DynamoDB' | 'S3' | 'RDS' | 'ElasticSearch'


/** The type of cache. Only applies if the `spec/type` is `cache`.
 * 
 * - 'Redis' for Redis clusters.
 * - 'Memcached' for Memcached clusters.
 */
export type CacheType = 'Redis' | 'Memcached'

/** A resource. See https://backstage.io/docs/features/software-catalog/descriptor-format#kind-resource */
export type Resource = ResourceEntity & {
    spec: {
        type: ResourceType;
        /** Only applies to type:database. */
        databaseType?: DatabaseType;
        /** Only applies to type:stream. */
        streamType?: StreamType;
        /** Only applies to type:cache. */
        cacheType?: CacheType;
        /** Only applies to type:datalake-table. Defaults to `system-internal` */
        interfaceExposureType?: InterfaceExposureType;
    }
}


/** The group types.
 * 
 * - 'vertical' for verticals (like Autoscout24, LeasingMarkt, AUTOproff or Autoplenum).
 * - 'business-unit' for business units (like "Technology Platform"). Usually associated with a C-level (like CTO).
 * - 'group' for groups (like "Seller Success Group Tech"). Usually a director is managing a group.
 * - 'team' for teams (like "Creation API"). Usually a EM is managing a team.
 */
export type GroupType = 'vertical' | 'business-unit' | 'group' | 'team'

/** A group. See https://backstage.io/docs/features/software-catalog/descriptor-format#kind-group */
export type Group = GroupEntity & {
    spec: {
        type: GroupType;
    }
}


export function genLink(node: DependencyGraphTypes.DependencyNode<EntityNode>) {
    return generatePath(`/catalog/${node.namespace}/${node.kind?.toLowerCase()}/${node.name}`)
}
