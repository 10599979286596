import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
  logo: {
    width: '100%',
    objectFit: 'cover',
    display: 'block',
    padding: 20,
    marginTop: -22,
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return <img className={classes.logo} src='/as24-logo-full.png' />
};

export default LogoFull;
