import { CatalogFilterLayout, EntityListProvider, EntityOwnerPicker, EntityTagPicker, UserListPicker } from '@backstage/plugin-catalog-react';
import { TechDocsPageWrapper } from './TechDocsPageWrapper';
import {
    TechDocsPicker,
    EntityListDocsTable,
    TechDocsIndexPageProps,
  } from '@backstage/plugin-techdocs';
import { SupportButton, ContentHeader, Content } from '@backstage/core-components';

export const CustomTechDocsHome = (props: TechDocsIndexPageProps) => {
    const { initialFilter = 'owned', columns, actions } = props;
    return (
      <TechDocsPageWrapper>
        <Content>
          <ContentHeader title="">
            <SupportButton />
          </ContentHeader>
          <EntityListProvider>
            <CatalogFilterLayout>
              <CatalogFilterLayout.Filters>
                <TechDocsPicker />
                <UserListPicker initialFilter={initialFilter} />
                <EntityOwnerPicker />
                <EntityTagPicker />
              </CatalogFilterLayout.Filters>
              <CatalogFilterLayout.Content>
                <EntityListDocsTable actions={actions} columns={columns} />
              </CatalogFilterLayout.Content>
            </CatalogFilterLayout>
          </EntityListProvider>
        </Content>
      </TechDocsPageWrapper>
    );
  };