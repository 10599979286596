import React from 'react';
import { DefaultEntityFilters } from '@backstage/plugin-catalog-react';
import { EntityFilter } from '@backstage/plugin-catalog-react';
import { EntityAutocompletePicker } from '@backstage/plugin-catalog-react';
import { TextFieldProps } from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';

class EntityDisasterTierFilter implements EntityFilter {
  constructor(readonly values: string[]) {}
  filterEntity(entity: Entity): boolean {
    const tier = entity.metadata?.disasterTier;
    return tier !== undefined && this.values.includes(tier);
  }
}

export type CustomFilters = DefaultEntityFilters & {
  disasterTiers?: EntityDisasterTierFilter;
};

interface EntityDisasterTierPickerProps {
  hidden?: boolean;
  initialSelectedOptions?: string[];
  InputProps?: TextFieldProps;
}

export const EntityDisasterTierPicker: React.FC<EntityDisasterTierPickerProps> = ({
  hidden,
  initialSelectedOptions,
  InputProps,
}) => {

  if (hidden) {
    return null;
  }

  return (
    <EntityAutocompletePicker<CustomFilters, 'disasterTiers'>
      label="Disaster Tier"
      name="disasterTiers"
      path="metadata.disasterTier"
      showCounts={false}
      Filter={EntityDisasterTierFilter}
      initialSelectedOptions={initialSelectedOptions}
      InputProps={InputProps}
      filtersForAvailableValues={[]} 
    />
  );
};