import React, { useState } from 'react';
import { Content, PageWithHeader } from '@backstage/core-components';
import { HomePageStarredEntities, HomePageRecentlyVisited, HomePageTopVisited } from '@backstage/plugin-home';
import {
    identityApiRef,
    useApi,
} from '@backstage/core-plugin-api';
import {
    UserProfileCard,
    GroupProfileCard,
} from '@backstage/plugin-org';
import { EntityProvider } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';
import { Button, Grid } from '@material-ui/core';
import {
    catalogApiRef,
} from '@backstage/plugin-catalog-react';
import GroupAdd from '@material-ui/icons/GroupAdd';
import {
    HomePageRequestedReviewsCard,
    HomePageYourOpenPullRequestsCard,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import { Popover, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

export const HomePage = () => {
    const catalogApi = useApi(catalogApiRef);
    // Gets current user identity
    const identityApi = useApi(identityApiRef);

    const entities = useAsync(async () => {
        return identityApi.getBackstageIdentity()
            .then(async (identity) => {
                const groupRef = (identity.ownershipEntityRefs ?? []).filter((ref) => ref.startsWith('group:'))[0]
                const { userEntityRef } = identity;

                let groupEntity = undefined;
                let userEntity = undefined;

                if (groupRef) {
                    groupEntity = await catalogApi.getEntityByRef(groupRef)
                }

                if (userEntityRef) {
                    userEntity = await catalogApi.getEntityByRef(userEntityRef)
                }

                return {
                    userEntity, groupEntity
                }
            })
    });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (entities.loading) {
        return null
    }

    const isUserCatalogEntity = entities?.value?.userEntity;

    return (
    <PageWithHeader themeId='home' title='Developer Portal'>
        <Content>

            <Grid container alignContent='center' alignItems='center'>
                <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                    <h1>Your Org</h1>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={6}>
                {
                    isUserCatalogEntity ?
                        <EntityProvider entity={entities?.value?.userEntity}>
                            <UserProfileCard variant='gridItem' />
                        </EntityProvider>
                        :
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <h1>You are logged in as a guest.</h1>
                                <IconButton onClick={handleClick}>
                                    <InfoIcon />
                                </IconButton>
                            </div>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <div style={{ padding: '10px' }}>
                                    <p>
                                        Guests cannot have ownership in the catalog. If you are a user in any of the <a href='catalog?filters%5Bkind%5D=group'><strong>groups</strong></a>, refer to the documentation <a href="https://docs.services.as24.tech/products/developer-portal/faq/#how-can-i-add-my-user"><strong>here</strong></a> to add your user.
                                    </p>
                                </div>
                            </Popover>
                        </div>
                }
                </Grid>

                <Grid item xs={12} md={6}>

                    {entities?.value?.groupEntity ?
                        <EntityProvider entity={entities?.value?.groupEntity}>
                            <GroupProfileCard variant='gridItem' />
                        </EntityProvider>
                        :
                        <div style={{ textAlign: 'center' }}>
                            <h1>You are not part of any team.</h1>
                            {isUserCatalogEntity && (
                                <Button startIcon={<GroupAdd />} variant='contained' color='primary' onClick={() => { location.href = 'https://docs.services.as24.tech/products/developer-portal/faq/#how-can-i-add-modify-or-delete-a-team-structure'; }}>
                                    Join one
                                </Button>
                            )}

                        </div>
                    }
                </Grid>

            </Grid>

            <Grid container alignContent='center' alignItems='center'>
                <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                    <h1>Your Entities</h1>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={4}>
                    <HomePageStarredEntities title='Starred' />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageRecentlyVisited />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomePageTopVisited />
                </Grid>

            </Grid>

            {isUserCatalogEntity && (
                <>
                    <Grid container alignContent='center' alignItems='center'>
                        <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                            <h1>Your GitHub activity</h1>
                        </Grid>
                    </Grid>
                            
                    <Grid container>
                        <Grid item md={6} xs={12}>
                            <HomePageRequestedReviewsCard query="is:open is:pr review-requested:@me archived:false org:AutoScout24"/>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <HomePageYourOpenPullRequestsCard query="is:open is:pr author:@me archived:false org:AutoScout24"/>
                        </Grid>
                    </Grid>
                </>
            )}
        </Content>
    </PageWithHeader>
    );
};