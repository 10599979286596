import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
  logo: {
    width: '100%',
    objectFit: 'cover',
    display: 'block',
    padding: 6,
  },
});

const LogoIcon = () => {
  const classes = useStyles();
  
  return <img className={classes.logo} src='/as24-logo-short.png' />
};

export default LogoIcon;
