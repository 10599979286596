import React from 'react';
import { DependencyGraphTypes } from '@backstage/core-components';
import { EntityNode } from '@backstage/plugin-catalog-graph';
import { ClassNameProps, CoordProps } from './KindAndTypeSelectionIcon';
import { StreamType, genLink } from './types';
import { CornerComponentsProps } from './NamedBox';

type ResourceIconProps = ClassNameProps & CoordProps & CornerComponentsProps & {
    node: DependencyGraphTypes.DependencyNode<EntityNode>;
}

export class DatabaseIcon extends React.Component<ResourceIconProps> {
    render() {
        const node = this.props.node;
        const databaseType = (node.spec as any).databaseType || '<unknown>';
        const link = genLink(node);
        return <GenericDatabaseIcon {...this.props} name={node.name} link={link} topline={`[${databaseType}]`} />
    }
}

export class DataLakeTableIcon extends React.Component<ResourceIconProps> {
    render() {
        const node = this.props.node;
        const databaseType = 'datalake-table';
        const link = genLink(node);
        return <GenericDatabaseIcon className='resourceIcon-datalake-table' {...this.props} name={node.name} link={link} topline={`[${databaseType}]`} />
    }
}

type GenericDatabaseIconProps = ClassNameProps & CoordProps & CornerComponentsProps & {
    name: string
    link: string
    topline?: string
}

export class GenericDatabaseIcon extends React.Component<GenericDatabaseIconProps> {
    render() {

        const name = this.props.name;
        const link = this.props.link;

        const topline = this.props.topline;

        const width = this.props.width;
        const height = this.props.height;

        // Curve of DB icon is 10px high (see path):
        const pad = 10;

        const topLeftX = 0, topLeftY = pad;
        const topRightX = width, topRightY = topLeftY;
        const bottomRightX = topRightX, bottomRightY = height - pad;
        const bottomLeftX = topLeftX, bottomLeftY = bottomRightY;

        const topLeft = `${topLeftX},${topLeftY}`;
        const topRight = `${topRightX},${topRightY}`;
        const bottomRight = `${bottomRightX},${bottomRightY}`;
        const bottomLeft = `${bottomLeftX},${bottomLeftY}`;

        const padTop = 10;

        const arcX = 70;
        const arcY = 10;
        const arc = `A${arcX},${arcY} `;

        return (
            <g className={this.props.className}>
                <>
                    <g transform='translate(0, 0)'>
                        <path
                            d={`M${topLeft}  ${arc} 0 0,1 ${topRight}  ${arc} 0 0,1 ${topLeft}   M${topRight}  L${bottomRight}  ${arc} 0 0,1 ${bottomLeft} L${topLeft}`}
                            fill="lightblue"
                            stroke='black'
                        />
                    </g>
                    <text className='text-main' x={width / 2} y={2 * height / 3} textAnchor='middle' alignmentBaseline='middle'>
                        <a className='link' href={link}>{name}</a>
                    </text>
                    {topline &&
                        <text className='text-subtitle' x={width / 2} y={padTop} textAnchor='middle' alignmentBaseline='middle'>{topline}</text>
                    }
                    {this.props.topRightComponent &&
                        this.props.topRightComponent
                    }
                    {this.props.topLeftComponent &&
                        this.props.topLeftComponent
                    }
                    {this.props.bottomLeftComponent &&
                        this.props.bottomLeftComponent
                    }
                    {this.props.bottomRightComponent &&
                        this.props.bottomRightComponent
                    }
                </>

            </g>
        );
    }

}

export class ApiStreamIcon extends React.Component<ResourceIconProps> {
    render() {
        const node = this.props.node;
        const streamType: StreamType | string = (node.spec as any).streamType || '<unknown>';
        const name = node.name;
        const link = genLink(this.props.node);
        return <PipeIcon {...this.props} name={name} link={link} topline={`[${streamType}]`} />
    }
}


export class StreamIcon extends React.Component<ResourceIconProps> {
    render() {
        const node = this.props.node;
        const streamType: StreamType | string = (node.spec as any).streamType || '<unknown>';
        const name = node.name;
        const link = genLink(this.props.node);
        return <PipeIcon {...this.props} name={name} link={link} topline={`[${streamType}]`} />
    }
}

export type PipeIconProps = ClassNameProps & CoordProps & CornerComponentsProps & {
    name: string
    link: string
    topline?: string
}

export class PipeIcon extends React.Component<PipeIconProps> {
    render() {

        const width = this.props.width;
        const height = this.props.height;

        // Curve of pipe icon is 10px high (see path):
        const pad = 10;

        const topLeftX = 2 * pad, topLeftY = 0;
        const topRightX = width - 2 * pad, topRightY = 0;
        const bottomRightX = topRightX, bottomRightY = height;
        const bottomLeftX = topLeftX, bottomLeftY = height;

        const topLeft = `${topLeftX},${topLeftY}`;
        const topRight = `${topRightX},${topRightY}`;
        const bottomRight = `${bottomRightX},${bottomRightY}`;
        const bottomLeft = `${bottomLeftX},${bottomLeftY}`;

        const padTop = 3;

        const arcX = 2;
        const arcY = 5;
        const arc = `A${arcX},${arcY} `;
        return (
            <g className={this.props.className}>
                <>
                    {/* This is a hack (opacity=0) to prevent infinity re-paints with endless zoom out */}
                    <rect x={0} y={0} width={width} height={height} opacity={0.0} />
                    <g transform='translate(0, 0)'>
                        <path
                            d={`M${topRight} ${arc} 0 0,1 ${bottomRight} ${arc} 0 0,1 ${topRight} M${bottomRight} L${bottomLeft}  ${arc} 0 0,1 ${topLeft} L${topRight}`}
                            fill="lightblue"
                            stroke='black'
                        />
                    </g>

                    <text className='text-main' x={width / 2} y={height / 2} textAnchor='middle' alignmentBaseline='hanging'>
                        <a className='link' href={this.props.link}>{this.props.name}</a>
                    </text>
                    {this.props.topline &&
                        <text className='text-subtitle' x={width / 2} y={padTop} textAnchor='middle' alignmentBaseline='hanging'>{this.props.topline}</text>
                    }
                    {this.props.topRightComponent &&
                        this.props.topRightComponent
                    }
                    {this.props.topLeftComponent &&
                        this.props.topLeftComponent
                    }
                    {this.props.bottomLeftComponent &&
                        this.props.bottomLeftComponent
                    }
                    {this.props.bottomRightComponent &&
                        this.props.bottomRightComponent
                    }
                </>

            </g>
        );
    }

}