import React from 'react';
import { ClassNameProps, CoordProps } from './KindAndTypeSelectionIcon';


export type CornerComponentsProps = {
    topRightComponent?: React.ReactNode;
    topLeftComponent?: React.ReactNode;
    bottomLeftComponent?: React.ReactNode;
    bottomRightComponent?: React.ReactNode;
}

type GenericIconProps = CornerComponentsProps & {
    name: string;
    link?: string
    subtitle?: string;
    icon?: React.ReactNode
}

/** 
 * A box with a name, optional subtitle, and an icon as parameter. 
 * 
 * It has extension points for custom elements in the "corners".
 */
class NamedBox extends React.Component<GenericIconProps & ClassNameProps & CoordProps> {
    render() {
        const width = this.props.width;
        const height = this.props.height;
        const iconSize = 24;
        return (
            <g className={this.props.className}>
                <rect x={0} y={0} width={width} height={height} rx='10' fill='#BBB' strokeWidth={0.25} stroke='black' />
                {this.props.icon &&
                    <g transform={`translate(${((width / 2) - iconSize / 2)}, 9)`}>
                        {this.props.icon}
                    </g>
                }
                <text x={width / 2} y={height / 2} textAnchor='middle' alignmentBaseline='middle'>
                    <tspan className='text-main' x={width / 2} fontSize='18' dy={10} textAnchor='middle' alignmentBaseline='middle'>
                        {this.props.link ?
                            <a className='link' href={this.props.link}>
                                {this.props.name}
                            </a>
                            :
                            <>{this.props.name}</>
                        }

                    </tspan>
                    {this.props.subtitle &&
                        <tspan className='text-subtitle' x={width / 2} fontSize='12' dy={height / 3} textAnchor='middle' alignmentBaseline='middle'>
                            {this.props.subtitle}
                        </tspan>}
                </text>
                {this.props.topRightComponent &&
                    this.props.topRightComponent
                }
                {this.props.topLeftComponent &&
                    this.props.topLeftComponent
                }
                {this.props.bottomLeftComponent &&
                    this.props.bottomLeftComponent
                }
                {this.props.bottomRightComponent &&
                    this.props.bottomRightComponent
                }

            </g>
        );
    }
}

export default NamedBox;
