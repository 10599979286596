/* eslint-disable no-console */
import { useApi } from '@backstage/core-plugin-api';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  GroupScanResult,
  GroupScanResultIssue,
  githubScannerApiRef,
} from './ScannerApi';
import {
  makeStyles,
  Theme,
  Grid,
  Paper,
  createStyles,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  TextField,
  Button,
  Box,
  Tabs,
  Tab,
} from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';
import { GroupEntity } from '@backstage/catalog-model';
import { CustomTabPanel } from './CustomTabPanel';
import { ScannerGroupIssuesTable } from './ScannerGroupIssuesTable';
import { ScanDateSelect } from './ScanDaySelector';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    filters: {
      padding: 20,
    },
  }),
);

export const ScannerGroupPage = () => {
  const classes = useStyles();
  const scannerApi = useApi(githubScannerApiRef);

  const [scanDate, setScanDate] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [groupData, setGroupData] = useState<GroupScanResult | undefined>(
    undefined,
  );
  const { entity: groupEntity } = useEntity<GroupEntity>();
  // All values from dataset
  const [availablePrios, setAvailablePrios] = useState<string[]>([]);
  const [availableProcessors, setAvailableProcessors] = useState<string[]>([]);
  const [availableRepos, setAvailableRepos] = useState<string[]>([]);
  // filters
  const [prios, setPrios] = useState<string[]>([]);
  const [processors, setProcessors] = useState<string[]>([]);
  const [repos, setRepos] = useState<string[]>([]);
  const [repoName, setRepoName] = useState('');
  // data for table
  const [issues, setIssues] = useState<GroupScanResultIssue[]>([]);

  // Reset filters and pre-select all filters based on issues
  // Do NOT use state `groupData` and use value passed ddirectly !
  const reset = (data?: GroupScanResultIssue[]) => {
    const _issues = data ?? [];
    const _processors = [...new Set(_issues.map(x => x.code))];
    const _prios = [...new Set(_issues.map(x => x.prio))];
    const _repos = [...new Set(_issues.map(x => x.repo_name))];
    setAvailableProcessors(_processors);
    setProcessors(_processors);
    setAvailablePrios(_prios);
    setPrios(_prios);
    setAvailableRepos(_repos);
    setRepos(_repos);
    setRepoName('');
  };

  useEffect(() => {
    const groupName = groupEntity.metadata.name; // Example: as24-creation-api
    if (scanDate) {
      setLoading(true);
      scannerApi
        .getForGroup(groupName, scanDate)
        .then(data => {
          setLoading(false);
          setGroupData(data);
          // init filters ans select all available values
          reset(data?.issues);
        })
        .catch(e => {
          setLoading(false);
          console.error(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanDate, scannerApi, groupEntity]);

  // recalculate visible table
  useEffect(() => {
    const issuesLocal = (groupData?.issues || []).filter(
      x => prios.includes(x.prio) && processors.includes(x.code),
    );
    console.log(issuesLocal);
    setIssues(issuesLocal);
  }, [groupData, prios, processors]);

  useEffect(() => {
    const issuesLocal = groupData?.issues || [];
    setIssues(
      issuesLocal.filter(
        x =>
          prios.includes(x.prio) &&
          processors.includes(x.code) &&
          x.repo_name.includes(repoName),
      ),
    );
  }, [groupData, prios, processors, repos, repoName]);

  const handlePrioChange = (event: ChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setPrios(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleRepositoryChange = (event: ChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setRepos(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleProcessorChange = (event: ChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setProcessors(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleRepositoryPartChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setRepoName(value);
  };

  const [tab, setTab] = useState(0);
  const handleTabChange = (event: any, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <ScanDateSelect setScanDate={setScanDate} />
        {loading ? <>Loading repository data ...</> : ''}
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1 }}>
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab label={`${groupData?.issues.length ?? 0} Issues`} />
              <Tab label={`${groupData?.failures.length ?? 0} Failures`} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tab} index={0}>
            <Paper elevation={1}>
              {issues && issues.length > 0 ? (
                <Grid item container xs={12} className={classes.filters}>
                  <Grid item xs={4}>
                    <InputLabel id="select-prio">Priority</InputLabel>
                    <Select
                      labelId="select-prio"
                      multiple
                      fullWidth
                      value={prios}
                      onChange={handlePrioChange}
                      renderValue={(x: any) => `(${x.length}) selected`}
                      input={<OutlinedInput />}
                    >
                      {availablePrios.map(name => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={prios.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel id="select-repo">Repository</InputLabel>
                    <Select
                      labelId="select-repo"
                      multiple
                      fullWidth
                      value={repos}
                      onChange={handleRepositoryChange}
                      renderValue={(x: any) => `${x.length} selected`}
                      input={<OutlinedInput />}
                    >
                      {availableRepos.map(name => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={repos.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel id="select-processor">Processor</InputLabel>
                    <Select
                      labelId="select-processor"
                      multiple
                      fullWidth
                      value={processors}
                      onChange={handleProcessorChange}
                      input={<OutlinedInput />}
                      renderValue={(x: any) => `${x.length} selected`}
                    >
                      {availableProcessors.map(name => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={processors.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel id="select-name-repo">
                      Repository name contains:
                    </InputLabel>
                    <TextField
                      id="select-name-repo"
                      value={repoName}
                      onChange={handleRepositoryPartChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => reset(groupData?.issues)}
                    >
                      Reset all filters
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Paper>
            <ScannerGroupIssuesTable name="Issues" issues={issues} />
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={1}>
            <ScannerGroupIssuesTable
              name="Failures"
              issues={groupData?.failures || []}
            />
          </CustomTabPanel>
        </Box>
      </Grid>
    </Grid>
  );
};
