import React from 'react';
import NamedBox, { CornerComponentsProps } from './NamedBox';
import { ClassNameProps, CoordProps, NodeProps } from './KindAndTypeSelectionIcon';
import { genLink } from './types';


type EntityIconProps = NodeProps & CoordProps & ClassNameProps & CornerComponentsProps & {
    icon?: React.ReactNode;
    showSubtitle?: boolean;
}

/** 
 * A backstage entity that is rendered as a box with an icon as parameter. 
 * It displays the name, kind, type. 
 * It links to the entity page.
 */
class EntityIcon extends React.Component<EntityIconProps> {
    render() {
        const specType: string = this.props.node.kind + '/' + (this.props.node.spec?.type as string || '(type is missing)');

        const subtitle = this.props.showSubtitle ? specType : undefined;
        const link = genLink(this.props.node);
        return (
            <NamedBox {...this.props}
                name={this.props.node.name}
                link={link}
                subtitle={subtitle}
                icon={this.props.icon}
            />
        );
    }
}

export default EntityIcon;
