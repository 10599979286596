import React from 'react';

import FlipToFrontIcon from '@material-ui/icons/FlipToFront';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';
import { Link, IconButton, Typography, Box } from '@material-ui/core';

export const DocsNavLinks: React.FC = () => (
  <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" marginRight={2} marginLeft={2} marginTop="-5px">
        <Link href="/docs/default/component/as24-application-docs" underline="none" >
          <Box display="flex" flexDirection="column" alignItems="center" marginRight={2}>
            <IconButton style={{ color: 'white',  paddingBottom: '0px', paddingTop: '0px', marginTop: '0px' }}>
              <FlipToFrontIcon />
            </IconButton>
            <Typography variant="body2" style={{ color: 'white'}} >Product Docs</Typography>
          </Box>
        </Link>
        <Link href="/docs/default/component/platform-docs" underline="none" >
          <Box display="flex" flexDirection="column" alignItems="center">
            <IconButton style={{ color: 'white' ,  paddingBottom: '0px',  paddingTop: '0px' , marginTop: '0px' }}>
              <FlipToBackIcon />
            </IconButton>
            <Typography variant="body2" style={{ color: 'white'}}>Platform Docs</Typography>
          </Box>
        </Link>
      </Box>
  </>
);