import { GroupEntity, UserEntity, stringifyEntityRef } from '@backstage/catalog-model';
import { InfoCard, Progress, ResponseErrorPanel, Avatar, Link, OverflowTooltip } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import {
  Grid,
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  
} from '@material-ui/core';
import { generatePath } from 'react-router-dom';
import {
  catalogApiRef,
  entityRouteParams,
  useEntity,
} from '@backstage/plugin-catalog-react';
import React from 'react';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[2],
      borderRadius: '4px',
      overflow: 'visible',
      position: 'relative',
      margin: theme.spacing(4, 1, 1),
      flex: '1',
      minWidth: '0px',
    },
  }),
);

const ManagerComponent = (props: { member: UserEntity }) => {
  const classes = useStyles();
  const {
    metadata: { name: metaName, description },
    spec: { profile },
  } = props.member;
  const displayName = profile?.displayName ?? metaName;

  return (
    <Grid item container xs={12} sm={12} md={8} xl={4}>
      <Box className={classes.card}>
        <Box
          display="flex"
          flexDirection="column"
          m={3}
          alignItems="center"
          justifyContent="center"
        >
          <Avatar
            displayName={displayName}
            picture={profile?.picture}
            customStyles={{
              position: 'absolute',
              top: '-2rem',
            }}
          />
          <Box
            pt={2}
            sx={{
              width: '100%',
            }}
            textAlign="center"
          >
            <Typography variant="h6">
              <Link
                data-testid="user-link"
                to={generatePath(
                  `/catalog/:namespace/user/${metaName}`,
                  entityRouteParams(props.member),
                )}
              >
                <OverflowTooltip text={displayName} />
              </Link>
            </Typography>
            {profile?.email && (
              <Link to={`mailto:${profile.email}`}>
                <OverflowTooltip text={profile.email} />
              </Link>
            )}
            {description && (
              <Typography variant="subtitle2">{description}</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};



export const ManagersListCard = (props: {
  managerType: 'owner' | 'productManager';
  memberDisplayTitle?: string;
}) => {
  const memberDisplayTitle = props.managerType === 'owner' ? 'Managers' : 'Product Managers';

  const { entity: groupEntity } = useEntity<GroupEntity>();
  const {
    metadata: { name: groupName },
    spec: { profile },
  } = groupEntity;
  const catalogApi = useApi(catalogApiRef);

  const displayName = profile?.displayName ?? groupName;

  const {
    loading,
    error,
    value: directMembers,
  } = useAsync(async () => {
    // TODO: We might have native (and multiple) Group owners when this is resolved https://github.com/backstage/backstage/issues/18823
    const manager = props.managerType === 'owner' ? (groupEntity.spec as any)?.owner : (groupEntity.spec as any)?.productManager;

    if (!manager) {
      return [];
    }

    const membersList = await catalogApi.getEntities({
      filter: {
        kind: 'User',
        'metadata.name': manager,
      },
    });

    return membersList.items as UserEntity[];
  }, [catalogApi, groupEntity]);

  const members = (directMembers ?? []).sort((a, b) =>
    stringifyEntityRef(a).localeCompare(stringifyEntityRef(b)),
  ) as UserEntity[];

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <Grid item>
      <InfoCard
        title={`${memberDisplayTitle} (${members?.length || 0})`}
        subheader={`of ${displayName}`}
      >
        <Grid container spacing={3}>
          {members && members.length > 0 ? (
            members.map(member => (
              <ManagerComponent member={member} key={member.metadata.uid} />
            ))
          ) : (
            <Box p={2}>
              <Typography>
                {' '}
                This group has no {memberDisplayTitle.toLocaleLowerCase()}.{' '}
              </Typography>
            </Box>
          )}
        </Grid>
      </InfoCard>
    </Grid>
  );
};

