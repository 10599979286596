import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface Props {
  setScanDate: (value: string) => void;
}

export const ScanDateSelect = ({ setScanDate }: Props) => {
  const values = Array(8)
    .fill(0)
    .map((_v, i) => i)
    .slice(1, 8)
    .map(x =>
      new Date(new Date().getTime() - x * 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10),
    );

  const [selected, setSelected] = React.useState(values[0]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value as string);
    setScanDate(event.target.value);
  };

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="scan-date-label">Scan Date</InputLabel>
        <Select
          labelId="scan-date-label"
          id="scan-date"
          value={selected}
          onChange={handleChange}
          label="Scan Date"
        >
          {values.map(x => (
            <MenuItem value={x} key={x}>{x}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
