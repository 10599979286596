/* eslint-disable no-console */
import { useApi } from '@backstage/core-plugin-api';
import React, { useEffect, useState } from 'react';
import { RepoScanResult, githubScannerApiRef } from './ScannerApi';
import {
  makeStyles,
  Theme,
  Grid,
  Paper,
  createStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ComponentEntity } from '@backstage/catalog-model';
import { RepositoryData } from './RepositoryData';
import { CustomTabPanel } from './CustomTabPanel';
import { ScannerRepositoryIssuesTable } from './ScannerRepositoryIssuesTable';
import { ScanDateSelect } from './ScanDaySelector';

const useStyles = makeStyles((_theme: Theme) => createStyles({}));

export const ScannerRepositoryPage = () => {
  const _classes = useStyles();
  const [scanDate, setScanDate] = useState<string | undefined>();
  const scannerApi = useApi(githubScannerApiRef);
  const [loading, setLoading] = useState(false);
  const [repository, setRepository] = useState<RepoScanResult | undefined>(
    undefined,
  );

  const { entity: componentEntity } = useEntity<ComponentEntity>();

  useEffect(() => {
    const repoName = componentEntity.metadata.name;
    if (scanDate) {
      setLoading(true);
      scannerApi
        .getForRepository(repoName, scanDate)
        .then(data => {
          setLoading(false);
          setRepository(data);
        })
        .catch(e => {
          setLoading(false);
          console.error(e);
        });
    }
  }, [scanDate, scannerApi, componentEntity]);

  const [tab, setTab] = useState(0);
  const handleTabChange = (event: any, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <ScanDateSelect setScanDate={setScanDate} />
        {loading ? (
          <>Loading repository data ...</>
        ):""}
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1 }}>
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab label={`${repository?.issues.length ?? 0} Issues`} />
              <Tab label={`${repository?.failures.length ?? 0} Failures`} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tab} index={0}>
            <ScannerRepositoryIssuesTable
              name="Issues"
              issues={repository?.issues || []}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={1}>
            <ScannerRepositoryIssuesTable
              name="Failures"
              issues={repository?.failures || []}
            />
          </CustomTabPanel>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <RepositoryData repo={repository?.repo} tools={repository?.tools} />
      </Grid>
      <Grid item xs={12}>
        {repository && repository.deps && repository.deps.length > 0 ? (
          <TableContainer component={Paper}>
            <Table aria-label="deps table" size="small">
              <TableHead style={{ whiteSpace: 'nowrap' }}>
                <TableRow>
                  <TableCell>Lang</TableCell>
                  <TableCell>Org</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Versions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ whiteSpace: 'nowrap' }}>
                {repository.deps.map(dep => (
                  <TableRow key={`${dep.dep_org}:${dep.dep_name}`}>
                    <TableCell>{dep.dep_language}</TableCell>
                    <TableCell>{dep.dep_org}</TableCell>
                    <TableCell>{dep.dep_name}</TableCell>
                    <TableCell>{dep.revisions.join(', ')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          'No dependencies detected'
        )}
      </Grid>
    </Grid>
  );
};
