import React from 'react';
import {
  Box,
  Typography,
  Divider,
  CardContent,
  CardHeader,
  Card
} from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import { CardActions, Button } from '@mui/material';


export const DisasterRecoveryCard = () => {
  const { entity } = useEntity<Entity>();
  const disasterRecovery: any[] = entity.metadata?.disaster_recovery as any[];
  const disasterTier = entity?.metadata?.disasterTier;

  return (
    <>
      {(disasterRecovery || disasterTier) && (
        <Card>
          <CardHeader title="Disaster Recovery"/>
          
          <Divider />
          <CardContent >
            {disasterTier && 
            <Box>
              <Typography variant='h6' style={{fontWeight:'normal'}}>Disaster Tier: {disasterTier}</Typography>
            </Box>
            }
         
            {disasterRecovery &&
              <Box display="flex" >
                {disasterRecovery?.map((item: any, index: number) => (
                  <Box key={index} flexGrow={1}>
                    <Typography variant='h6' style={{fontWeight:'normal'}}>{item.name} : {item.value}</Typography>
                    <Typography variant="body2" >{item.description}</Typography>
                  </Box>
                ))}
              </Box>
            }
          </CardContent>
                      
          <CardActions>
            <Button size="small" target="_blank" href="https://docs.services.as24.tech/security/aws/disaster-recovery/criticality-tiers/#rto-and-rpo">Learn More</Button>
          </CardActions>

        </Card>
      )}
    </>
  );

};
