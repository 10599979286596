import React from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core';

interface Props {
  name: string;
  org?: string;
}
const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    link: {
      cursor: 'pointer',
      "&:hover": {
        textDecoration: "underline",
      },
    },
  }),
);

export const GithubRepositoryLink = ({ name, org = 'Autoscout24' }: Props) => {
  const classes = useStyles();
  return (
    <a
      target="blank"
      className={classes.link}
      rel={`repository-${name}`}
      href={`https://github.com/${org}/${name}`}
    >
      {name}
    </a>
  );
};
