import * as React from 'react';
import { GroupScanResultIssue } from './ScannerApi';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { IssuePrio } from './IssuePrio';
import { GithubRepositoryLink } from './GithubRepositoryLink';

interface Props {
  issues: GroupScanResultIssue[];
  name: string;
}

export const ScannerGroupIssuesTable = ({ issues, name }: Props) => {
  if (issues.length === 0) {
    return <div>{`No scanning ${name} detected`}</div>;
  }
  return (
    <TableContainer component={Paper}>
      <Table aria-label="issues table" size="small">
        <TableHead style={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            <TableCell>Prio</TableCell>
            <TableCell>Repository</TableCell>
            <TableCell align="right">Processor</TableCell>
            <TableCell>Issue</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody style={{ whiteSpace: 'nowrap' }}>
          {issues.map((issue, idx) => (
            <TableRow key={idx}>
              <TableCell>
                <IssuePrio prio={issue.prio} />
              </TableCell>
              <TableCell>
                <GithubRepositoryLink name={issue.repo_name} />
              </TableCell>
              <TableCell align="right">{issue.code}</TableCell>
              <TableCell style={{ whiteSpace: 'break-spaces' }}>
                {issue.issue}
              </TableCell>
              <TableCell>
                {issue.help_link ? (
                  <a href={issue.help_link} target="blank">
                    Solution Guide
                  </a>
                ) : (
                  ''
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
