import { useLocation } from 'react-router-dom';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { CatalogIndexPage } from '@backstage/plugin-catalog';
import {
  EntityListProvider,
  EntityKindPicker,
  EntityLifecyclePicker,
  EntityNamespacePicker,
  EntityOwnerPicker,
  EntityProcessingStatusPicker,
  EntityTagPicker,
  EntityTypePicker,
  UserListPicker,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import { EntityDisasterTierPicker } from './EntityDisasterTierPicker';
  
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const CustomCatalogPage = () => {
  const orgName =
    useApi(configApiRef).getOptionalString('organization.name') ?? 'Developer Portal';
  const query = useQuery();
  const kindFilter = query.get('filters[kind]');
  const isGroupOrUser = kindFilter === 'group' || kindFilter === 'user';

  return (
    <EntityListProvider>
      <CatalogIndexPage filters = {
        <>
        <EntityKindPicker allowedKinds={['component', 'api', 'resource', 'system']} hidden={isGroupOrUser}/>   
        <EntityTypePicker />
        <UserListPicker />
        <EntityOwnerPicker />
        <EntityLifecyclePicker />
        <EntityTagPicker />
        <EntityProcessingStatusPicker />
        <EntityNamespacePicker />
        <EntityDisasterTierPicker hidden={isGroupOrUser} />
        </>
      }      
    />
    </EntityListProvider>
  );
};