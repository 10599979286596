import React from 'react';

const AS24ChatPage = () => {
  return (  
    <div className="AS24ChatPage" style={{height: '100vh'}}>
      <iframe title="AS24 Chat" src="https://as24-chat-research.s24-data-products.aws.as24.tech" style={{height: '100%', width: '100%'}} />
    </div>
  );
};

export const as24ChatPage = <AS24ChatPage />;
