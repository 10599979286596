import {
    createTechDocsAddonExtension,
    TechDocsAddonLocations,
  } from '@backstage/plugin-techdocs-react';
import { DocsNavLinks } from './components/addons/DocsNavLinks';
import { techdocsPlugin } from '@backstage/plugin-techdocs';
  
  
export const DocsNavAddon = techdocsPlugin.provide(
    createTechDocsAddonExtension({
        name: 'DocsNavAddon',
        location: TechDocsAddonLocations.Header,
        component: DocsNavLinks,
    }),
);
