import { ConfigApi, createApiRef, IdentityApi } from '@backstage/core-plugin-api';

//
// Copy of backend definitions !!
//
/**
 * Repo scan results (combined)
 */
export interface RepoScanResult {
  repo: RepoScanResultData;
  issues: RepoScanResultIssue[];
  failures: RepoScanResultIssue[];
  deps: RepoScanDep[];
  tools: RepoTool[];
}

export interface RepoTool {
  tool_name: string;
  revisions?: string[];
}

export interface RepoScanDep {
  dep_org: string;
  dep_name: string;
  dep_language: string;
  revisions: string[];
}

export interface RepoScanResultIssue {
  code: string;
  prio: string;
  issue: string;
  help_link?: string;
}
export interface RepoScanResultData {
  scan_date: string;
  repo_name: string;
  org_name: string;
  template_features: string[];
  iac_kinds: string[];
  count_files: Record<string, number>;
  owner: string;
  git_pr_count: number;
  git_issues_count: number;
  git_languages: Record<string, number>;
  git_contributors: string[];
  git_private: boolean;
  git_fork: boolean;
  git_forks_count: number;
  git_size: number;
  git_commits_weekly: number[];
  git_commits_year_total: number;
  git_pushed_at: string;
  git_created_at: string;
  git_updated_at: string;
}

/**
 * Group scan results (combined)
 */
export interface GroupScanResult {
  issues: GroupScanResultIssue[];
  failures: GroupScanResultIssue[];
}

export interface GroupScanResultIssue {
  repo_name: string;
  code: string;
  prio: string;
  issue: string;
  help_link?: string;
}

export interface GithubScannerApi {
  getForRepository: (
    repoName: string,
    scanDate: string,
  ) => Promise<RepoScanResult | undefined>;

  getForGroup: (
    groupName: string,
    scanDate: string,
  ) => Promise<GroupScanResult | undefined>;
}

export const buildAs24ScannerApi = (
  configApi: ConfigApi,
  identityApi: IdentityApi
): GithubScannerApi => {
  const backendUrl = configApi.getString('backend.baseUrl');
  const requestUrl = (name: string) =>
    `${backendUrl}/api/github-scanner/${name}`;

  const getForRepository = async (
    repoName: string,
    scanDate: string,
  ): Promise<RepoScanResult | undefined> => {
    const { token } = await identityApi.getCredentials();
    const response = await fetch(requestUrl('repository'), {
      method: 'POST',
      body: JSON.stringify({
        repoName,
        scanDate,
      }),
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
         Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.data ? (data.data as RepoScanResult) : undefined;
  };

  const getForGroup = async (
    groupName: string,
    scanDate: string,
  ): Promise<GroupScanResult | undefined> => {
    const { token } = await identityApi.getCredentials();
    const response = await fetch(requestUrl('group'), {
      method: 'POST',
      body: JSON.stringify({
        groupName,
        scanDate,
      }),
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
         Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data.data ? (data.data as GroupScanResult) : undefined;
  };

  return {
    getForRepository,
    getForGroup,
  };
};

export const githubScannerApiRef = createApiRef<GithubScannerApi>({
  id: 'github-scanner-api',
});
