export const isProduction = () => process.env.NODE_ENV !== 'development';

export function generateMissingAnnotationYaml(items?: string[]): string {
    if (!items) {
      return '';
    }
    
    return `
  apiVersion: backstage.io/v1alpha1
  kind: Component
  metadata:
    name: example-component
    description: This is an example component with the following configurations:
    annotations:
  ${items.map(item => `    ${item}: value`).join('\n')}
  spec:
    type: service
    lifecycle: production
    owner: group:as24-cloud-developer-experience
  `;
  }
  
export const generateApiDefinitionMissingYaml = (): string => `
  apiVersion: backstage.io/v1alpha1
  kind: API
  metadata:
    name: example-api
    description: This is an example API.
  spec:
    type: openapi
    lifecycle: production
    owner: group:as24-cloud-developer-experience
    definition:
      $text: value
  `;