import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { CodeSnippet } from '@backstage/core-components';
import { Link } from '@backstage/core-components';

interface MissingConfigurationProps {
  title?: string;
  description?: string;
  items?: string[];
  links?: string[];
  exampleYamlGenerator?: (items?: string[]) => string;
}

const MissingConfiguration: React.FC<MissingConfigurationProps> = ({ 
  title = "Missing Configuration", 
  description, 
  items, 
  links, 
  exampleYamlGenerator 
}) => {
  
  const defaultExampleYaml = items
    ? items.map(item => `${item}: value`).join('\n')
    : '';

  const getExampleYaml = (): string => {
    if (items && exampleYamlGenerator) {
      return exampleYamlGenerator(items);
    } else if (items && !exampleYamlGenerator) { 
      return defaultExampleYaml;
    } else if (!items && exampleYamlGenerator) {
      return exampleYamlGenerator();
    }
    
    return '';
  };

  return (
    <Box>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1">
        {description}
      </Typography>
      <Box mt={2}>
        <Typography variant="body2">Example:</Typography>
        <CodeSnippet
          text={getExampleYaml()}
          language="yaml"
          showLineNumbers
        />
      </Box>
      <Box mt={2}>
        {links?.map((link, index) => (
          <Button
            key={index}
            color="primary"
            component={Link}
            to={link}
            style={{ marginRight: '8px' }}
          >
            Read More
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default MissingConfiguration;
