import React from 'react';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import WarningOutlined from '@material-ui/icons/WarningOutlined';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import { red, orange, green } from '@material-ui/core/colors';

interface Props {
  prio: string;
}

export const IssuePrio = ({ prio }: Props) => {
  if (prio === 'high') {
    return <CancelOutlined style={{ color: red[500] }} />;
  }
  if (prio === 'med') {
    return <WarningOutlined style={{ color: orange[500] }} />;
  }
  if (prio === 'low') {
    return <InfoOutlined style={{ color: green[500] }} />;
  }
  return (<div>{prio}</div>)
};
