import React from 'react';

import { Header, Page } from '@backstage/core-components';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';
import { Box, IconButton, Link, Typography } from '@material-ui/core';

/**
 * Props for {@link TechDocsPageWrapper}
 *
 * @public
 */
export type TechDocsPageWrapperProps = {
  children?: React.ReactNode;
};

/**
 * Component wrapping a TechDocs page with Page and customized Header components
 *
 * @public
 */
export const TechDocsPageWrapper = (props: TechDocsPageWrapperProps) => {
  const { children } = props;
  const configApi = useApi(configApiRef);
  const generatedSubtitle = `Documentation available in ${
    configApi.getOptionalString('organization.name') ?? 'Backstage'
  }`;

  return (
    <Page themeId="documentation" >
      <Header title="Documentation" subtitle={generatedSubtitle}>
        <Box display="flex" justifyContent="space-between" marginRight={5}>
          <Link href="/docs/default/component/as24-application-docs" style={{ color: 'white' }} >
            <Box display="flex" alignItems="center" marginRight={2}>
              <IconButton color="inherit">
                <FlipToFrontIcon />
              </IconButton>
              <Typography variant="h6" style={{ color: 'white' }}>Product Docs</Typography>
            </Box>
          </Link>
          <Link href="/docs/default/component/platform-docs" style={{ color: 'white' }}>
            <Box display="flex" alignItems="center">
              <IconButton color="inherit">
                <FlipToBackIcon />
              </IconButton>
              <Typography variant="h6" style={{ color: 'white' }}>Platform Docs</Typography>
            </Box>
          </Link>
        </Box>
      </Header>
      {children}
    </Page>
  );
};