import React from 'react';
import {
  Box,
  Typography,
  Divider,
  CardContent,
  CardHeader,
  Card,
  List,
  ListItem
} from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import { CardActions, Button } from '@mui/material';


export const SecurityInformationCard = () => {
  const { entity } = useEntity<Entity>();
  const availability: boolean = entity.metadata.availability as boolean;
  const confidentiality: string = entity.metadata?.confidentiality as string;
  const integrity: boolean = entity.metadata?.integrity as boolean;
  const exposure: string[] = entity.metadata?.exposure as any[];
  
  return (
    <>
      {(availability || confidentiality) && (
        <Card >
          <CardHeader title="Security Attributes" />
          <Divider />
          <CardContent >
            { 
            <Box>
              <Typography variant='h6' style={{fontWeight:'normal'}}>Availability: {availability.toString()}</Typography>
            </Box>
            }
            {confidentiality && 
            <Box>
              <Typography variant='h6' style={{fontWeight:'normal'}}>Confidentiality: {confidentiality}</Typography>
            </Box>
            }
            <Box>
              <Typography variant='h6' style={{fontWeight:'normal'}}>Integrity: {integrity.toString()}</Typography>
            </Box>
            {exposure &&
              <Box display="flex" >
                <Typography variant='h6' style={{fontWeight:'normal'}}>Exposure: </Typography>
                <List>
                {exposure?.map((item: string) => ( 
                  <ListItem style={{paddingBottom:0}}>                                                   
                    <Typography variant='h6' style={{fontWeight:'normal'}}>- {item}</Typography>                    
                  </ListItem>                                 
                ))}
                </List>
              </Box>
            }
            <CardActions>
              <Button size="small" target="_blank" href="https://docs.services.as24.tech/best-practices/metadata/#security-attributes">Learn More</Button>
            </CardActions>
          </CardContent>
        </Card>
      )}
    </>
  );
};
